import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout';
import TextBanner from '../components/text-banner/text-banner';
import OverlayBlockTeam from '../components/overlay-block-team/overlay-block-team';
import StaffMembersBlock from '../components/staff-block/staff-block';

class WebsiteAndTechnology extends React.Component {
    render() {
        const websiteTextBanner = get(this, 'props.data.websiteTextBanner');
        const websiteOverlayBlockTeam = get(this, 'props.data.websiteOverlayBlockTeam');
        const websiteStaffMembersBlock = get(this, 'props.data.websiteStaffMembersBlock');
        return (
            <Layout location={this.props.location}>
                <Seo title="Meet The Team"/>
                    <div className="container-fluid container-fluid--page">
                        <div className="meet-team">
                            <TextBanner textOne={websiteTextBanner.textOne} textTwo={websiteTextBanner.textTwo} />
                            <OverlayBlockTeam image={websiteOverlayBlockTeam.backgroundImage.url} headingText={websiteOverlayBlockTeam.headingText}
                                descriptionText={websiteOverlayBlockTeam.descriptionText} boxOneNumber={websiteOverlayBlockTeam.boxOneNumber}
                                boxOneText={websiteOverlayBlockTeam.boxOneText} boxTwoNumber={websiteOverlayBlockTeam.boxTwoNumber}
                                boxTwoText={websiteOverlayBlockTeam.boxTwoText} />
                            <StaffMembersBlock members={websiteStaffMembersBlock.nodes} />
                        </div>
                    </div>
            </Layout>
        )
    }
}

export default WebsiteAndTechnology;

export const query = graphql`
query WebsiteAndTechnology {
    websiteTextBanner: contentfulTextBanner(textBannerName: {eq: "websiteTextBanner"}) {
        textBannerName
        textOne
        textTwo
    }
    websiteOverlayBlockTeam: contentfulOverlayBlockTeam(overlayBlockName: {eq: "websiteOverlayBlockTeam"}) {
        overlayBlockName
        headingText
        descriptionText
        boxOneNumber
        boxOneText
        boxTwoNumber
        boxTwoText
        backgroundImage {
            url
        }
    }
    websiteStaffMembersBlock: allContentfulStaffMembersBlock(
        filter: {staffMembersBlockName: {glob: "*websiteStaffMembersBlock*"}}
      ) {
        nodes {
          name
          description
          image {
            url
          }
        }
    }
}
`
